.container {
    background-color: white;
    /* border: 1px solid #475467; */
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: black;
    line-height: 24px;
}

.view_more {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: black;
}

.chart_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.svgContainer {
    display: flex;
    justify-content: space-between;
}

.legendContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.legend {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.legend_label {
    display: flex;
    align-items: center;
    gap: 4px;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.label {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.legend_value {
    color: black;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}
