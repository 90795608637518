.navigation-block {
    display: flex;
    justify-content: flex-end;
}

.navigation-page-selector {
    flex-basis: 240px;
}
.navigation-progress-container {
    display: flex;
    flex-direction: column;
    align-items: end;
}
.navigation-buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
/* .navigation-button {
    line-height: 24px;
    font-size: 14px;
    padding: 10px 32px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    background-color: var(--bs-primary);
    color: #fff;
    cursor: pointer;
    outline: none;
  }
  .navigation-button:focus {
    color: #fff;
    background-color: #fff;
    box-shadow: none
  }
  .navigation-button:hover {
    color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    background-color: var(--bs-primary);
  } */
.navigation-text-container {
    position: relative;
    width: 100%;
}
.navigation-text {
    position: absolute;
    right: 0;
    top: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.45);
}
