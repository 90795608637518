.container {
    width: 100%;
    position: relative;
}

.svgContainer {
    color: #98a2b3;
    fill: #d0d5dd;
}

.header {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #d0d5dd;
    line-height: 24px;
}

.legendContainer {
    display: flex;
    align-items: center;
    gap: 32px;
}

.legend {
    display: flex;
    align-items: center;
    gap: 4px;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.label {
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
}

/* .htCircle {
  background-color: #a363ff;
}

.ttCircle {
  background-color: #00ff9e;
} */

.tooltip {
    position: absolute;
    z-index: 10;
    padding: 10px;
    border-radius: 8px;
    background: #d8dce3;
    color: #101828;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    visibility: hidden;
}
