.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #d0d5dd;
    line-height: 30px;
}
