
/* new dashboard css style */
.dash_graph_height {
    display: flex;
}
.table_bars .txt_ellipses {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: inherit;
    max-width: 180px;
}

.no_graph_data {
    align-items: center;
    justify-content: center;
    display: flex;
}

.no_graph_data img {
    width: 300px;
}
.graph_tables.table thead tr th,
.graph_tables.table tbody tr td {
    padding: 7px 0px;
    color: #344054;
    font-size: 12px;
}

.table_bars.table thead tr th {
    /* padding: 7px 0; */
}

.card_legends_box.client_card_only .card_legends {
    padding: 66px 15px;
}
.card_legends_box.card_padd_against_donut .card_legends
{
    padding: 47px 15px;
}
.card_legends_box.card_padd_against_verticalChart .card_legends
{
    padding: 77px 15px;
}

.select2_custom {
    width: 280px;
}

.select2_custom .check_select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
    position: relative;
}

.select2_custom .check_select:checked {
    background-color: var(--bs-brand_light_green);
    /* Change this to your desired color */
    border-color: #4caf50;
    /* Optional: Make the border the same as the fill color */
}

.select2_custom .check_select:checked::after {
    /* content: '';
    position: absolute;
    top: 2px;
    left: 5px;
    width: 4px;
    height: 8px;
    border: solid red;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); */
}

.multiselect_scroll_only .css-13cymwt-control,
.multiselect_scroll_only .css-t3ipsp-control
{
    height: 55px;
    /* overflow-y: scroll;
    border: 1px solid red; */
    box-shadow: none;
}
.multiselect_scroll_only .css-1dyz3mf
{
    height: 55px;
    overflow-y: scroll;
}
.multiselect_scroll_only .css-9jq23d
{
    padding: 0 3px;
}
.multiselect_scroll_only .css-1dyz3mf::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    height: 6px;
}

.multiselect_scroll_only .css-1dyz3mf::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: #808080;
}

.multiselect_scroll_only .css-1dyz3mf::-webkit-scrollbar {
    width: 7px;
    height: 5px;
}

/* Track */
.multiselect_scroll_only .css-1dyz3mf::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.multiselect_scroll_only .css-1dyz3mf::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d0d5dd;

}

/* Handle on hover */
.multiselect_scroll_only .css-1dyz3mf ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.graph_sub_heading {
    font-size: 14px;
    margin: 0 0 15px 0;
}

.dash_graph .list_sentimate li {
    margin: 0 0 10px 0;
    color: #475467;
    letter-spacing: 0.3px;
}

.horizontal_chart_only {
    padding: 0 15px 0 0 !important;
}

.horizontal_chart_only .horizontal_chart_align_only {
    top: -14px;
    position: relative;
}



.table_width_bar table
{
    font-size: 12px;
    color: #344054;
}
.tbl_custom_reporing thead tr th
{
    padding-bottom: 10px;
}
.tbl_custom_reporing tody tr td
{
    padding: 8px 0;
}
.tbl_custom_reporing .ascending-icon:before,
.tbl_custom_reporing .descending-icon:before
{
    font-family: 'FontAwesome';
    left: 5px;
    position: relative;
    color: #98A2B3;
    font-size: 15px;
    top: 1px;
}
.tbl_custom_reporing .ascending-icon:before
{
    content: "\f107";
}
.tbl_custom_reporing .descending-icon:before
{
    content: "\f106";
}

.apexcharts-legend .legend-label {
    display: inline-block;
    width: 100px;
}

.apexcharts-legend .legend-data {
    display: inline-block;
}

.apexcharts-legend .legend-percentage {
    display: inline-block;
}
.legend-parent {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    text-align: left;
}
.td_tbl_ellipsis
{
    display: inline-flex;
    height: 21px;
}
.coa_tbl_ellipsis
{
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: inherit;
    word-break: break-all;
    max-width: 140px;
}






.fixed_column_table {
    position: relative;
    overflow-x: auto;
    width: 100px;
    min-width: 100%;
}

.fixed_column_table .table {
    display: block;
    overflow-x: scroll !important;
    white-space: nowrap;
    width: 100%; /* Let the table adapt to the container width */
}

/* Fixed first header (th) */
.fixed_column_table .table thead th:first-child {
    position: sticky;
    left: 0;
    background: #f9fafb; 
    z-index: 3; 
}

/* Fixed first column (td) */
.fixed_column_table .table tbody td:first-child {
    position: sticky;
    left: 0;
    background: #fff; /* Column background for visibility */
    z-index: 1; /* Place it below the header */
}

/* Add a minimum width for columns to ensure they don't shrink */
.fixed_column_table .table th,
.fixed_column_table .table td {
    min-width: 100px; /* Adjust as needed */
}
