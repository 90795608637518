.container {
    position: relative;
    width: 100%;
}

.title {
    color: #d0d5dd;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.barLabel {
    fill: #344054;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}
.barLabel2 {
    fill: #fcfcfd;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.barValue {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    fill: #98a2b3;
}

.tooltip {
    position: absolute;
    z-index: 10;
    padding: 10px;
    border-radius: 8px;
    background: #d8dce3;
    color: #101828;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    visibility: hidden;
}
