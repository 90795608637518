.sentiment_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: white;
}

.sentiment_header {
    display: flex;
    justify-content: space-between;
}

.sentiment_body {
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 16px;
    width: 100%;
}

.sentiment_two_chart_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.sentiment_chart_container {
    display: flex;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    flex-basis: 50%;
    position: relative;
}

.box_container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: white;
    border-radius: 8px;
    width: 100%;
}

.sentiment_second {
    display: grid;
    grid-template-columns: 60% 39%;
    gap: 16px;
}

.title {
    color: var(--Gray-Input-Bg, black);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.box_container_body {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.tweets_box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 10px 15px;
    border-radius: 8px;
}

.tweets,
.label {
    color: var(--Gray-Placeholder, var(--color-grey-light-grey-1, black));
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.label {
    color: var(--Gray-Placeholder, var(--color-grey-light-grey-1, black));
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.reason_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
}

.reason_inner_container {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.reason_inner_container > p {
    color: var(--Gray-Placeholder, var(--color-grey-light-grey-1, black));
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.sentiment_analysis {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.sentiment_inner {
    width: 100%;
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
}

.sentiment_inner :nth-child(1) {
    /* flex: 0 0 80%; */
}

.sentiment_inner :nth-child(2) {
    flex: 0 0 20%;
}

.second_container {
    display: flex;
    gap: 16px;
    align-items: center;
}
