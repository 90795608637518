.app-container-demo .card {
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.app-container-demo .card-header {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}

.app-container-demo table {
    width: 100%;
    border-collapse: collapse;
}

.app-container-demo thead th {
    /* background-color: #f4f4f4; */
    cursor: pointer;
    padding: 10px;
    color: rgb(105, 110, 123);
    font-weight: 500;
    font-size: 14px;
    text-align: left;
}

.app-container-demo tbody td {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500;
}

.clickable:hover {
    color: #131f23; /* Lighter blue color */
    text-decoration: underline;
    cursor: pointer; /* Underline on hover */
}
