.container {
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: white;
    border: 1px solid var(--bs-border-color);
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header > h4 {
    color: var(--Gray-Line, black);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.legends_container {
    display: flex;
    gap: 8px;
    align-items: center;
}

.legends_container > div {
    display: flex;
    gap: 4px;
    align-items: center;
}

.legends_container span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.legends_container p {
    color: var(--White, black);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.body {
    width: 100%;
}
