.blank_lbl {
  display: block;
}

.select2_custom .css-1nmdiq5-menu {
  padding: 15px 5px 15px 15px;
}
.select2_custom .check_select {
  margin-right: 5px;
}

.cali_assign_tbl_only .cali_name {
  max-width: 210px;
  width: 210px;
}

.date_range_last .rdrDateRangePickerWrapper {
  left: auto;
  right: 0;
}

.error .form-control {
  border: 1px solid var(--bs-danger);
}
.error .error_message {
  display: block;
}

.green_light_bg {
  background-color: #f3fff3;
}
.green_light_bg .score_icon {
  background: #a7ffa5;
}

.grey_light_bg {
  background-color: #f4f5f9;
}
.grey_light_bg .score_icon {
  background: #c1c6e0;
}

.red_light_bg {
  background-color: #FFF2F2;
}
.red_light_bg .score_icon {
  background: #F5C7C7;
}

.yellow_light_bg {
  background-color: #fffdf2;
}
.yellow_light_bg .score_icon {
  background: #fff59e;
}

.error_message {
  display: none;
  color: var(--bs-danger);
  font-size: 15px;
  padding: 5px 0 0 0;
}

.title_buton_style {
  background-color: #e9eaf3;
  display: inline-block;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 16px;
}

.was-validated .form-check-input:valid:checked {
  background-color: var(--bs-primary-shade-20) !important;
}

.form_style_comman.was-validated .form-select:valid:not([multiple]):not([size]) {
  background-image: none;
}

.custom_field_wrap {
  margin-bottom: 40px;
}
.custom_field_wrap .cus_field_title {
  border-bottom: 1px solid #ebebeb;
  align-items: center;
}
.custom_field_wrap .cus_field_title h6 {
  font-size: 14px;
  color: var(--bs-dark-grey);
  margin: 0;
}
.custom_field_wrap .custom_field_icon {
  min-width: 123px;
}
.custom_field_wrap .custom_field_icon .data_type {
  color: #979292;
  font-size: 13px;
}
.custom_field_wrap .custom_field_list_area .custom_field_item h5 {
  font-size: 14px;
}
.custom_field_wrap .custom_field_list_area .custom_field_item p {
  font-size: 13px;
  font-style: italic;
}

.cus_all_datatype .MuiGrid-item {
  margin-bottom: 7px;
  max-width: 33.333%;
}

.custom_more {
  position: relative;
}
.custom_more:before {
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  top: 12px;
  background-color: #BBB3B3;
  width: 100%;
}
.custom_more .more_less {
  background-color: #fff;
  z-index: 1;
  position: relative;
  padding: 0 0 0 10px;
  cursor: pointer;
}

.cus_field_area {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 390px;
  padding-right: 6px;
}

.audit_sheet_edit_modal .modal-dialog {
  max-width: 980px;
}

.audit_sheet_Accordian .accordion-button {
  padding: 10px 10px;
  color: var(--dark);
}
.audit_sheet_Accordian button:not(.collapsed) {
  background-color: transparent;
}
.audit_sheet_Accordian .accordion-item {
  position: relative;
}
.audit_sheet_Accordian .accordion-body {
  padding: 10px 10px;
}
.audit_sheet_Accordian .accordion-body .add_reason_scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 290px;
  padding-right: 6px;
}
.audit_sheet_Accordian .accordion-body .form-group {
  margin-bottom: 8px;
}

.audit_edit_modal_body .accordion-collapse {
  position: absolute;
  width: 100%;
  background: #ffffff;
  z-index: 1;
  border: 1px solid #E0E0E0;
  border-top: none;
}

.eq_hight {
  min-height: 69px;
}/*# sourceMappingURL=main.css.map */