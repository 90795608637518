
/* === login first screen === */
.login_area {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0 7px;
  }

  .lg_form_left {
    background: #171A22;
    background-image: url('../images/omindSideImg.svg');
  background-size: cover;
  }
  .lg_form_left,
  .lg_form_right {
    height: 100vh;
  }
  .lg_form_right {
    display: flex;
    align-items: center;
  }
  .lg_form {
    width: 100%;
  }
  .login_bg {
    display: flex !important;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    align-items: end;
    position: relative;
    padding: 40px 50px 30px 30px;
  }
  .login_logo_box
  {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 9999;
  }
  .login_banner_text
  {

  }
  .login_banner_text h2
  {
    color: var(--bs-white);
    font-size: 32px;
    font-weight: 600;
  }
  .login_banner_text p{
    color: rgba(255,255,255,0.75);
    font-size: 14px;
  }
  .lgn_form_area {
  }
  .p_right {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
  }
  .btn_full {
    width: 100%;
  }
  .heading_title {
    font-size: 32px;
    padding: 0 0 8px 0;
    margin: 0;
    color: #1d2939;
    font-weight: 600;

  }
  
  .lgn_form_area .MuiFormControl-root {
    width: 100%;
  }
  .lgn_form_area .MuiInputBase-input {
    padding: 9.5px 14px;
  }
  .lgn_form_area .MuiLoadingButton-root {
    width: 100%;
    background: var(--bs-primary);
    border: none;
    padding: 10px;
    border-radius: 4px;
    box-shadow: none;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
  }
  .lgn_form_area .MuiLoadingButton-root:hover {
    background: var(--bs-brand_medium_green);
    box-shadow: none;
  }
  .lgn_form_area .login_error {
    color: #ff0000;
    font-size: 14px;
  }
  .lgn_form_area .form-label {
    font-size: 14px;
    padding: 0 0 5px 0;
    margin: 0;
    font-weight: 600;
  }
  .lgn_form_area .field-icon {
    position: absolute;
    z-index: 2;
    right: 14px;
    top: 6px;
    color: rgba(96, 94, 92, 1);
    cursor: pointer;
    padding: 8px;
    font-size: 18px;
  }
  .lgn_form_area .form-check-label {
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: #605e5c;
  }
  .lgn_form_area .forgot_link {
    font-size: 14px;
    font-style: normal;
    color: var(--blue) !important;
    font-weight: 600;
    text-decoration: none;
  }
  .lgn_form_area .form-check .form-check-label {
  }
  .lgn_form_area .form-check .form-check-input {
    border: 1px solid #605e5c;
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }
  .lgn_form_area .form-check-input:checked {
    background-color: var(--blue);
    border-color: var(--blue);
  }
  .forgot_pass_div {
    text-align: right;
  }
  .login_logo img {
  }
  .otp_timer p,
  .resend_code a {
    font-size: 14px;
    color: #848484;
  }
  .resend_code a {
    font-weight: 600;
    color: #d9d9d9;
    text-align: right;
    text-decoration: none;
  }
  .pass_hint {
    font-size: 12px;
    color: #848484;
    margin: 4px 0 0 0;
  }
  .pass_changed_box {
    display: flex;
    align-items: center;
    position: relative;
  }
  .pass_changed_box .heading_title {
    font-weight: 700;
  }
  .pass_changed_box .login_btn_box {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 20px;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }
  
  /* client login style */
  #client_password_verification .heading_title {
    margin-top: 30px;
  }
  
  .server_setup_form .MuiOutlinedInput-input {
    padding: 10.5px 14px;
    border: none;
  }
  
  /* main server setup style */
  .login_left_side {
    background-color: #1e2845;
    color: #fff;
    height: 100vh;
    position: sticky;
    top: 0;
  }
  .login_left_box {
    padding: 30px 20px;
    position: relative;
    height: 100%;
    text-align: center;
  }
  .login_left_box .login_left_bottom {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .login_left_bottom h3 {
    color: #fff;
    font-size: 26px;
    margin: 0 0 17px 0;
    font-family: "Roboto Slab", serif;
  }
  .login_left_bottom h3 span {
    color: #fd7e50;
  }
  .login_left_bottom p {
    color: #b4bedd;
    font-size: 16px;
    margin: 0 0 10px 0;
  }
  .login_right_side {
    background-color: #fcf9f6;
  }
  .login_right_box {
    padding: 40px 120px 30px 70px;
    position: relative;
  }
  .login_right_form {
    max-width: 413px;
    width: 100%;
  }
  .login_form_top h3 {
    color: #354265;
    font-size: 20px;
    margin: 0 0 10px 0;
    font-weight: 700;
  }
  .lgn_form_area .MuiOutlinedInput-notchedOutline
  {
    /* border: 1px solid var(--bs-primary) !important; */
    border-width: 1px !important;
  }
  .lgn_form_area .error_input .MuiOutlinedInput-notchedOutline,
  .lgn_form_area .error_input .MuiOutlinedInput-notchedOutline:hover,
  .lgn_password_error .MuiOutlinedInput-notchedOutline,
  .lgn_password_error .MuiOutlinedInput-notchedOutline:hover,
  .lgn_password_error .MuiOutlinedInput-notchedOutline:focus
  {
    border-color: var(--bs-danger) !important;
  }

  /* .lgn_form_area .MuiOutlinedInput-notchedOutline.Mui-focused .MuiOutlinedInput-notchedOutline
  {
    border: 1px solid var(--bs-primary) !important;
  } */
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
  {
    border-color: var(--bs-primary) !important;
  }


