.DIYOrganizationTree span.k-icon {
    width: 20px;
    display: inline-block;
    cursor: pointer;
}

.DIYOrganizationTree {
    ul {
        list-style: none;
        margin: 0;
        padding: 0 0 0 15px;

        li {

            .k-treeview-top,
            .k-treeview-mid,
            .k-treeview-bot {
                display: flex;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin-bottom: 7px;
                padding: 6px 10px;

                .k-treeview-leaf {
                    width: 100%;
                }
            }

            .k-treeview-leaf-text {
                display: flex;
                justify-content: space-between;

                .diy_rgt_icon {}
            }

            .k-treeview-toggle {}
        }
    }
}


// 
.diy_treeview {
    .tree {
        list-style: none;
        padding: 0 0 0 0px;

        li {
            color: var(--dark);
            font-size: 16px;

            .checkbox-icon {
                // top: 5px;
                // position: relative;
            }

            .diy_items_inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .pointer_cur
                {
                    img{
                        width: 20px;
                    }

                }
            }

            svg.arrow {
                width: 22px;
                height: 22px;
            }

            .form-control {
                height: 30px;
                border-color: transparent;
            }

            .tree-node {
                border: 1px solid #ccc;
                border-radius: 4px;
                display: flex;
                align-items: center;
                margin-bottom: 7px;
                padding: 6px 15px;
            }

            .tree-node-group {
                list-style: none;

                .tree-leaf-list-item {
                    list-style: none;
                    margin-left: 0;
                }

                li {
                    // border: 1px solid red;
                }
            }
        }
    }

}

.obs_user_creations .accordion-item {
    border: none;
    position: relative;
}

.obs_user_creations .accordion-button {
    border: 1px solid #ccc;
    border-radius: 4px;
    color: var(--dark);
    padding: 11px 10px 11px 37px;
}

.obs_user_creations button:not(.collapsed) {
    background-color: initial;
}

.obs_user_creations .accordion-body {
    padding: 7px 0;
}

.obs_user_creations .accordion-button:after {
    left: 10px;
    position: absolute;
}
