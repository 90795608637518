.notice_feed_area {
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
}

.delete_post_modal .modal-dialog {
  max-width: 390px !important;
}
.delete_post_modal .modal-dialog .alret_modal_body {
  padding: 24px 20px;
}
.delete_post_modal .modal-dialog .alret_modal_body h6 {
  font-size: 16px;
}

.notice_feed_area img {
  max-width: 380px;
  width: 100%;
}

.notice_feed_area h4 {
  font-size: 22px;
}

.notice_feed_create_modal .modal-dialog {
  max-width: 1000px;
}

.notice_feed_create_modal .tag-remove {
  position: relative;
  right: auto;
  top: auto;
}

.notice_feed_create_modal .react-dropdown-tree-select .tag-list .tag {
  width: auto;
  margin-right: 10px;
  font-size: 12px;
}

.notice_feed_create_modal .react-dropdown-tree-select .dropdown {
  display: block;
}

.notice_feed_create_modal .tooltipobs {
  position: absolute;
  background: #000;
  color: #fff;
  z-index: 9;
  padding: 7px 10px;
  border-radius: 4px;
  top: 43px;
  left: 0;
  display: none;
}

.notice_feed_create_modal .react-dropdown-tree-select .tag-list .tag:hover .tooltipobs {
  display: block;
}

.notice_feed_create_modal .react-dropdown-tree-select .tag-list .tag:hover::after {
  display: none;
}

/*  */
.notice_feed_create_modal .react-dropdown-tree-select .dropdown ::-webkit-scrollbar {
  height: 6px;
  /* Set the height of the horizontal scrollbar */
}

/* Handle */
.notice_feed_create_modal .react-dropdown-tree-select .dropdown ::-webkit-scrollbar-thumb {
  background: #D5D5D5;
  /* Set the color of the scrollbar handle */
}

/* Handle on hover */
.notice_feed_create_modal .react-dropdown-tree-select .dropdown ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
  /* Change the color of the scrollbar handle on hover */
}

.notice_feed_create_modal .drpdown_tree_select .tag-item:not(:first-child) {
  display: block;
}

/*  */
.quill_textarea {
  /* height: 150px; */
  position: relative;
}

.quill_textarea .ql-editor {
  color: #000;
}

.quill_textarea .ql-link,
.quill_textarea .ql-image,
.quill_textarea .ql-video,
.quill_textarea .ql-picker-,
.quill_textarea .ql-clean,
.quill_textarea .ql-size,
.quill_textarea .ql-color.ql-expanded,
.quill_textarea .ql-background,
.quill_textarea .ql-indent,
.quill_textarea .ql-strike,
.quill_textarea .ql-color.ql-picker.ql-color-picker {
  display: none !important;
}

.quill_textarea .ql-container.ql-snow {
  height: 190px;
  border-radius: 3px;
}

.quill_textarea .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}

.quill_textarea .ql-toolbar.ql-snow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.quill_textarea .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid var(--bs-border-color);
  padding-bottom: 50px;
}

.quill_textarea .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  margin-bottom: 0px;
  bottom: 100%;
  top: auto;
  z-index: 1;
}

.quill_textarea .ql-editor li::before {
  width: auto;
}

/*  */
.sidebar-right .page_nav li a {
  padding-left: 0;
}

.sidebar-right .page_nav li a:before {
  display: none;
}

.feed_card {
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 25px;
  border: 1px solid #EEEEF2;
}

.blog_post .post_head_left {
  margin-right: 15px;
}

.blog_post .post_head_left .post_user_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 35px;
}

.blog_post .post_head_right .posted_name h4 {
  font-size: 16px;
  color: #292929;
  font-weight: 600;
}

.blog_post .post_head_right .posted_name .post_user_desig {
  padding-left: 24px;
}

.blog_post .post_desc_area .post_title_main {
  font-size: 16px;
  color: #292929;
  font-weight: 600;
}

.post_obs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.post_obs ul li {
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #E0E0E0;
  background-color: #F0F0F0;
  padding: 1px 10px;
  color: #616161;
  border-radius: 3px;
  font-size: 13px;
}

.post_desc_area {
  max-height: 190px;
  padding-right: 6px;
  overflow-x: hidden;
}

.blog_post .post_desc_area {
  color: #292929;
  letter-spacing: 0.3px;
  word-break: break-word;
}

.blog_post .post_desc_area h1 {
  font-size: 34px;
}

.blog_post .post_desc_area h2 {
  font-size: 28px;
}

.blog_post .post_desc_area h3 {
  font-size: 24px;
}

.blog_post .post_desc_area h4 {
  font-size: 20px;
}

.blog_post .post_desc_area h5 {
  font-size: 18px;
}

.blog_post .post_desc_area h6 {
  font-size: 16px;
}

.post_desc_area ol li,
.post_desc_area ul li {
  margin-bottom: 10px;
}

.like_comment_row {
  border-top: 1px solid #D8D4D4;
  padding: 10px 0 0 0;
}

.like_comment_row ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.like_comment_row ul li {
  display: inline-block;
  position: relative;
  margin-right: 16px;
  margin-bottom: 0;
}

.like_comment_row ul li a {
  padding: 6px 6px !important;
  border-radius: 3px;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
}

.like_comment_row > ul > li:first-child:after {
  content: "";
  height: 17px;
  width: 1px;
  background-color: #C5C1C1;
  position: absolute;
  right: -8px;
  top: 9px;
}

.like_comment_row ul li a:hover {
  background-color: #E9EBF4;
  color: #000 !important;
}

.like_comment_row ul li a.chat_active .count {
  color: var(--bs-primary);
}

.like_comment_row ul li .dropdown-toggle::after {
  display: none;
}

.like_comment_row ul li:hover ul {
  display: block;
}

.like_comment_row ul li ul {
  background-color: transparent;
  box-shadow: none;
  position: relative;
}

.like_comment_row ul li ul li {
  padding: 4px 0px;
  color: #484964;
  margin: 0;
  display: block;
}

.like_comment_row.comment_like_row {
  border: none;
  padding-top: 5px;
}

.like_comment_row.comment_like_row ul li a {
  font-size: 10px;
}

.like_comment_row.comment_like_row ul li ul li {
  font-size: 13px;
  padding: 3px 0px;
}

.like_comment_row.comment_like_row > ul > li:first-child:after {
  display: none;
}

.feedback_textbox_area .feedback_textarea {
  position: relative;
}

.feedback_textbox_area .feed_send_btn {
  position: absolute;
  right: 22px;
  top: 10px;
  width: 27px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedback_textbox_area .feedback_textarea .form-control {
  padding-right: 50px;
}

.commented_list .media_body .media_body_bg {
  background-color: #F7F7F7;
  padding: 15px;
  border-radius: 3px;
}

.para_action .para_edit_ddn .dropdown-toggle {
  padding: 3px 4px;
  background-color: transparent;
  border: none;
}

.para_action .para_edit_ddn .dropdown-menu span {
  min-width: 21px;
  display: inline-block;
}

.para_action .para_edit_ddn .dropdown-item {
  display: flex;
  align-items: center;
  padding: 7px 15px;
}

.para_action .para_edit_ddn .dropdown-item a {
  color: var(--dark);
  font-size: 16px;
}

.user_commented_box .commented_like_box {
  color: #181818;
  font-size: 11px;
  font-weight: 600;
  display: flex;
}

.user_commented_box {
  position: relative;
}

.user_commented_box .user_commented_action {
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 1;
}

.user_commented_box .active_hide {
  opacity: 0.5;
}

.comment_chat_list_wrap {
  /* display: none; */
}

.viewall_comment {
  padding-left: 46px;
}

.feed_tooltip_wrap {
  position: relative;
}

.feed_tooltip_wrap .feed_tooltip {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  top: 24px;
  min-width: 120px;
  display: none;
  white-space: nowrap;
  z-index: 2;
}

.feed_tooltip_wrap .feed_tooltip:before {
  content: "\f0d8";
  font-family: "FontAwesome";
  position: absolute;
  top: -12px;
  color: #000;
}

.feed_tooltip_wrap:hover .feed_tooltip {
  display: block;
}

.post_obs .nav-list li:nth-child(4) .feed_tooltip {
  margin: 0 auto;
  transform: translate(-50%, 0px);
}

.post_obs .nav-list li:nth-child(4) .feed_tooltip:before {
  transform: translate(-50%, 0px);
  left: 50%;
}

.knowledge_tab_only .post_obs .nav-list li:nth-child(3) .feed_tooltip,
.knowledge_tab_only .post_obs .nav-list li:nth-child(4) .feed_tooltip {
  transform: translate(0, 0px);
  right: 0;
}

.knowledge_tab_only .post_obs .nav-list li:nth-child(3) .feed_tooltip:before,
.knowledge_tab_only .post_obs .nav-list li:nth-child(4) .feed_tooltip:before {
  transform: translate(0%, 0px);
  right: 10px;
  left: auto;
}

/* draft page style */
.bordered_btn.btn-primary {
  background-color: transparent;
  color: var(--bs-primary);
}

.draft_listing_area {
  overflow-x: hidden;
  max-height: calc(100vh - 331px);
  padding-right: 10px;
}

.draft_list_row {
  border: 1px solid var(--dark_gray);
  position: relative;
  padding: 12px 20px;
  padding-left: 40px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.draft_list_row h5 {
  font-size: 16px;
}

.draft_list_row .draft_list_check {
  position: absolute;
  left: 13px;
  top: auto;
}

.bottom_scroll {
  width: 50px;
  height: 50px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: fixed;
  right: 40px;
  bottom: 40px;
  color: #fff;
  font-size: 32px;
  transition: all 0.5s ease-in-out;
}

.bottom_scroll a {
  color: var(--bs-white);
}

.feed_alert_message {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: auto;
  display: inline-block;
  text-align: center;
  z-index: 999;
}

.successfully_post_message p {
  display: inline-block;
  padding: 10px 17px;
  border-radius: 3px;
  font-weight: 600;
}

.successfully_post_message p:hover {
  background-color: #F1FAF1;
}

/* form_wizard_step */
.form_wizard_step {
  padding: 10px 0;
  border-top: 1px solid rgb(220, 222, 255);
  border-bottom: 1px solid rgb(220, 222, 255);
}

.form_wizard_step ul {
  position: relative;
  display: inline-flex;
  margin-bottom: 0 !important;
}

.form_wizard_step ul::before {
  content: "";
  width: 68%;
  height: 2px;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 9px;
  right: 0;
  margin: 0 auto;
}

.form_wizard_step ul li {
  display: inline-block;
  min-width: 210px;
  z-index: 1;
}

.form_wizard_step ul li .step_icon {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
  border: 5px solid #e0e0e7;
}

.form_wizard_step ul li:nth-child(2) {
  margin: 0 15px;
}

.form_wizard_step #top-tab-list li.active.done a i,
.form_wizard_step #top-tab-list li.active.done a p,
.form_wizard_step ul li.active p {
  color: var(--bs-primary);
}

#top-tab-list li.active.done a {
  background: transparent;
}

.form_wizard_step ul li p {
  color: rgb(137, 143, 143);
  font-size: 13px;
  margin: 3px 0 0 0;
}

.form_wizard_step #top-tab-list li a {
  background-color: transparent !important;
  color: rgb(137, 143, 143);
  padding: 0;
}

.form_wizard_step ul li.active.done p {
  color: var(--bs-success) !important;
}

.form_wizard_step ul li.active .step_icon {
  border-color: var(--bs-primary);
}

.form_wizard_step ul li.active.done .step_icon {
  border-color: var(--bs-success);
}

.mandatory_field {
  color: var(--bs-danger);
  top: -3px;
}

.questions_set {
  margin-bottom: 23px;
}

.questions_set .ques_title {
  padding: 0 15px;
}

.questions_set .ques_option_wrap {
  background-color: #f7f7f7;
  padding: 16px 16px;
}

.questions_set .ques_option_wrap p {
  font-size: 14px;
  margin: 0 0 5px 0 !important;
}

.questions_set .option_repeat {
  margin-bottom: 10px;
}

.questions_set .ques_del {
  top: 7px;
  position: relative;
}

.pkt_score_box_main .pkt_score_box .score_icon {
  width: 38px;
  height: 38px;
  min-width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.pkt_score_box_main .pkt_score_box .dash_content h3 {
  font-size: 24px;
  font-weight: 600;
  color: #484964;
}
.pkt_score_box_main .pkt_score_box .dash_content h4 {
  color: #484964;
  font-size: 16px;
}

.status_date {
  font-size: 12px;
}

.pkt_test_reort_modal .modal-dialog {
  max-width: 841px;
}

.result_profile_area .result_profile {
  background-color: #EEEEEE;
  padding: 15px 15px;
}

.test_res_report_area .test_result_box {
  margin-bottom: 25px;
  word-break: break-all;
}
.test_res_report_area .test_result_box h4 {
  font-size: 16px;
  margin: 0 0 7px 0;
}
.test_res_report_area .test_result_box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.test_res_report_area .test_result_box li {
  align-items: center;
  display: flex;
  margin-bottom: 6px;
}
.test_res_report_area .test_result_box li .circle {
  width: 16px;
  height: 16px;
  min-width: 16px;
  border: 1px solid #ccc;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  text-align: center;
}
.test_res_report_area .test_result_box li.correct_answer {
  color: var(--bs-brand_green);
}
.test_res_report_area .test_result_box li.correct_answer .circle {
  border-color: var(--bs-brand_green);
}
.test_res_report_area .test_result_box li.correct_answer .circle:before {
  content: "";
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  min-width: 8px;
  min-height: 8px;
  display: flex;
  background-color: var(--bs-brand_green);
}
.test_res_report_area .test_result_box li.wrong_answer {
  color: var(--bs-danger);
}
.test_res_report_area .test_result_box li.wrong_answer .circle {
  border-color: var(--bs-danger);
}
.test_res_report_area .test_result_box li.wrong_answer .circle:before {
  content: "";
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  min-width: 8px;
  min-height: 8px;
  display: flex;
  background-color: var(--bs-danger);
}
.test_res_report_area .test_result_box li.attempted_answer {
  color: var(--bs-primary);
}
.test_res_report_area .test_result_box li.attempted_answer .circle {
  border-color: var(--bs-primary);
}
.test_res_report_area .test_result_box li.attempted_answer .circle:before {
  content: "";
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  min-width: 8px;
  min-height: 8px;
  display: flex;
  background-color: var(--bs-primary);
}

.report_view_modal_scroll {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  padding-right: 6px;
}

.exit_browser_modal .modal-dialog {
  max-width: 492px !important;
  width: 100%;
}
.exit_browser_modal .modal-dialog .modal-content {
  border-radius: 0;
  padding: 10px 14px;
  top: -23px;
}
.exit_browser_modal .modal-dialog .modal-content .modal-header {
  font-size: 23px;
  font-weight: 600;
}
.exit_browser_modal .modal-dialog .modal-content .exit_brow_btn {
  padding: 9px 40px;
  border: 1px solid #D1D1D1;
  color: #292929;
  font-size: 16px;
  border-radius: 4px;
}

.test_analysis_area .test_analysis_top ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.test_analysis_area .test_analysis_top li {
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}
.test_analysis_area .test_analysis_list .test_analysis_box {
  border: 1px solid var(--bs-border-color);
  padding: 15px 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  word-break: break-word;
}
.test_analysis_area .test_analysis_list .test_analysis_box .progress {
  border-radius: 30px;
}
.test_analysis_area .test_analysis_list .test_analysis_box .progress .btn_transparent {
  background-color: transparent;
  border: none;
}
.test_analysis_area .test_analysis_list .test_analysis_box .progress .btn_transparent:hover {
  background-color: transparent;
  border: none;
}

.pkt_publish_modal .modal-dialog {
  max-width: 606px;
}

.publish_obs .post_obs ul li {
  padding: 6px 15px;
  margin-bottom: 10px;
  background-color: #F6F7FF;
  border-color: #D5DBFF;
  color: #263991;
}
.publish_obs .post_obs ul li.obs_more {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
}

.agent_pkt_tbl_height {
  max-height: 390px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 6px;
}

.test_disabled {
  pointer-events: none;
  opacity: 0.4;
}

.test_exam_area {
  border: 1px solid #A3ABD2;
  border-radius: 7px;
  padding: 10px 10px;
  color: #484964;
}
.test_exam_area .test_exam_bx {
  border-right: 1px solid #7F7878;
}
.test_exam_area .test_exam_bx:last-child {
  border-right: none;
}
.test_exam_area .test_exam_bx_inr {
  padding: 10px 0;
}

.test_res_status {
  background-color: #FFF8F2;
  border: 1px solid #FFDABB;
  border-radius: 7px;
  padding: 15px 10px;
}
.test_res_status .res_certificate img {
  max-width: 80px;
}
.test_res_status .res_summary_bx h5 {
  font-size: 16px;
}

.test_ques_wrap .test_ques_area {
  max-height: calc(100vh - 232px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 6px;
}

.test_exam_block {
  margin-bottom: 30px;
}

.radio_group .form-check {
  margin-bottom: 10px;
  font-size: 14px;
}
.radio_group .form-check input[type=radio]:checked + label {
  color: var(--bs-primary);
}

.error_alert .form-control,
.error_alert .form-control:focus {
  border-color: var(--bs-danger) !important;
}
.error_alert .react-dropdown-tree-select .dropdown .dropdown-trigger {
  border-color: var(--bs-danger) !important;
}
.error_alert .quill_textarea {
  border: 1px solid var(--bs-danger);
  border-radius: 4px;
}
.error_alert .err_message {
  color: var(--bs-danger);
}

.no_overflow_x.page-wrapper {
  overflow-x: visible !important;
}

.NoticeFeedContentPane {
  overflow-x: hidden;
  max-height: calc(100vh - 248px);
  padding-right: 10px;
}

.like_dropdown {
  max-height: 360px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  background: white;
  border-radius: 5px;
  z-index: 100;
  padding: 10px;
  overflow-y: auto;
}
.like_dropdown .like_view_more_btn {
  background-color: rgba(160, 174, 192, 0.1490196078);
  border: none;
  font-size: 13px;
  color: #000;
}

.pkt_anlysis_tab_height {
  overflow-x: hidden;
  max-height: calc(100vh - 400px);
  padding-right: 10px;
}

.pkt_anlysis_tab_height_agent {
  overflow-x: hidden;
  max-height: calc(100vh - 210px);
  padding-right: 10px;
}

.back_btn_gray {
  background-color: #EAEAEA;
  color: #545454;
  padding: 7px 15px;
}
.back_btn_gray:hover {
  background-color: #EAEAEA;
}
.back_btn_gray:focus {
  background-color: #EAEAEA;
}

.back_btn_added.breadcrumb_box {
  margin-bottom: 16px;
}

.back_btn_added.breadcrumb_box .breadcrumb {
  margin-bottom: 0;
}

.back_btn_added .breadcrumb {
  margin-bottom: 0;
}

.badge_btn {
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
}

.badge_btn.soft_gray:hover {
  background-color: #F0F0F0;
}

.badge_btn.soft_yellow:hover {
  background-color: #fffef5;
}

.badge_btn.soft_blue:hover {
  background-color: #EBF3FC;
}

.badge_btn.soft_green:hover {
  background-color: #F1FAF1;
}

.pkt_anlysis_tab_height .pkt_test_name {
  font-size: 16px;
  margin: 0 0 3px 0;
}
.pkt_anlysis_tab_height p {
  font-size: 12px;
}

.timer_box {
  width: 108px;
  height: 108px;
  border: 4px solid var(--bs-border-color);
  border-radius: 50%;
  text-align: center;
  color: #BDBDBD;
  flex-wrap: wrap;
  position: fixed;
  right: 30px;
  background-color: #fff;
  top: 77px;
}
.timer_box h4 {
  font-size: 24px;
  color: #BDBDBD;
  padding: 28px 0 0 0;
}
.timer_box p {
  font-size: 16px;
  top: -5px;
  position: relative;
}/*# sourceMappingURL=knowledgemanagement.css.map */