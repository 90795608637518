:root {
    --positive: rgba(148, 190, 76, 0.75);
    --neutral: rgb(102, 112, 133);
    --negative: rgb(136, 8, 8);
}

.clickable:hover {
    color: #131f23; /* Lighter blue color */
    text-decoration: underline;
    cursor: pointer; /* Underline on hover */
}

.app-container-demo {
    display: flex;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100vh; /* Ensure it takes full viewport height */
}

.app-container-demo .dashboard {
    display: grid;
    grid-template-rows: auto 1fr; /* Top small cards and bottom large cards */
    gap: 20px;
    width: 100%;
}

.app-container-demo .small-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 15px;
}

.app-container-demo .large-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two large cards per row */
    gap: 20px;
}

.app-container-demo .large-card,
.small-card {
    background: white;
    border-radius: 4px;
    border: 1px solid var(--bs-border-color);
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 16px;
    box-sizing: border-box;
}

.app-container-demo .large-card {
    height: auto;
}

.app-container-demo .small-card {
    height: auto;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    min-height: 92px;
}

.app-container-demo .dashcard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.app-container-demo .card-body-demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app-container-demo .scalar-value-container {
    text-align: center;
}

.app-container-demo .scalar-value {
    color: #484964;
    margin: 7px 0 0 0;
    font-weight: 700;
    font-size: 20px;
}

.app-container-demo .scalar-title-container {
    /* text-align: center; */
    /* margin-top: 8px; */
}

.app-container-demo .scalar-title {
    color: #484964;
    font-size: 14px;
    margin: 0;
    vertical-align: middle;
}

.app-container-demo .card-title,
.card_title_only h4 {
    font-size: 15px !important;
    color: #344054 !important;
    font-weight: 600 !important;
    line-height: 1.5 !important;
    margin-left: 4px !important;
}

.app-container-demo .large-card-content {
    margin-top: 20px;
}

.OverallSentiment_sentiment_container__W5ZpF .Container_container__tbO6D {
    box-shadow: none;
}
.OverallSentiment_sentiment_container__W5ZpF .Container_header__HnP6p > h4 {
    font-size: 17px;
    color: #344054;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
}
.dashboard p {
    margin: 0;
}

.app-container-demo .large-card-other {
    padding-bottom: 120px;
}

.large-card-wise {
    display: flex;
    flex-direction: column;
}
.graph_tbl table .AnalyticsTable_table_header__UyH2J {
    /* border-bottom: 1px solid #eee; */
}
.AnalyticsTable_table_body__8vW5i {
    /* border-bottom: 1px solid #eee; */
}

.text_icon_line {
    display: flex;
    align-items: center;
}
/*  */
.analytics_card_box {
    display: flex;
}
.analytics_card {
    background: #f9f9f9;
    border-radius: 4px;
    border: 1px solid var(--bs-border-color);
    padding: 12px 15px;
    box-sizing: border-box;
    width: 100%;
}
.analytics_card .scalar-title {
    margin: 0 0 15px 0;
    color: #484964;
    font-size: 14px;
    vertical-align: middle;
}
.title_legend_line {
    position: relative;
}
.title_legend_line .recharts-legend-wrapper {
    /* position: absolute !important;
    top: 12px !important; */
}
.title_legend_line .recharts-default-legend {
    text-align: right !important;
}
.title_legend_line .recharts-legend-item:last-child {
    margin-right: 0 !important;
}
.title_legend_line .recharts-wrapper {
    /* margin-top: -50px !important; */
}
.quality_score_graph_only.title_legend_line .recharts-wrapper {
    /* margin-top: -30px !important; */
}
.quality_score_graph_only.title_legend_line .recharts-legend-wrapper {
    position: absolute !important;
    top: 5px !important;
    right: -5px !important;
}
.quality_score_graph_only .custom_legend_wrap {
    /* margin-right: -20px !important; */
    /* margin-top: -42px; */
}
.custom_legend_wrap {
    display: flex;
    /* justify-content: flex-end; */
}
.custom_legend_wrap .custom_legend {
    margin-right: 15px;
}
.large-card .card-title h6 {
    color: #344054;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
}

.back-button {
    position: fixed;
    top: 70px;
    background-color: #171a22;
    color: white;
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.back-button:hover {
    background-color: #333;
    color: #3d4458;
}
