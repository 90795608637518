.table_container {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 20px;
    border-radius: 8px;
    /* border: var(--Spacing-spacing-nome, 1px) solid var(--Gray-Subtitle, #475467); */
    /* background: #2e3033; */
    color: rgb(78, 91, 166);
}

.table_header > h6 {
    color: #344054;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.table_container > table {
    border-collapse: collapse;
    width: 100%;
}

.table_header > * {
    padding: 12px 16px;
    color: var(--White, #d0d5dd);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
}

.table_body > * {
    padding: 12px 16px;
    gap: 4px;
    /* border-bottom: 1px solid var(--Gray-Subtitle, #475467); */
    color: rgb(78, 91, 166);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.table_body:last-child > * {
    border-bottom: none;
}

.table_header > h6 {
    padding: 0px;
}
